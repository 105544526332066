import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';

class Carousel extends Component {
  	render() {
		const { t } = this.props;
		return	<div className={this.props.classes}>
					<div id="carousel-reservar" className={`carousel slide`} data-ride="carousel">
						<ol className={`carousel-indicators`}>
							{this.props.images.map((item, i) => {
								if (item.remoteImage && item.remoteImage.childImageSharp) {
									return (<li key={`carousel-indicator-` + i} data-target="#carousel-reservar" data-slide-to={i} className={`${i === 0 ? "active" : ""}`}></li>);
								} else {
									return null;
								}
							})}
						</ol>
						<div className={`carousel-inner`}>
							{this.props.images.map((item, i) => {
								if (item.remoteImage && item.remoteImage.childImageSharp) {
									return (<div key={`carousel-item-` + i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
										<img src={item.remoteImage.childImageSharp.fixed.src} srcSet={item.remoteImage.childImageSharp.fixed.srcSet} alt={item.alt} title={item.title} className={`d-block w-100`}></img>
									</div>);
								} else {
									return null;
								}
							})}
						</div>
						<a className={`carousel-control-prev`} href="#carousel-reservar" role="button" data-slide="prev">
							<span className={`carousel-control-prev-icon`} aria-hidden="true"></span>
							<span className={`sr-only`}>{t("Anterior")}</span>
						</a>
						<a className={`carousel-control-next`} href="#carousel-reservar" role="button" data-slide="next">
							<span className={`carousel-control-next-icon`} aria-hidden="true"></span>
							<span className={`sr-only`}>{t("Siguiente")}</span>
						</a>
					</div>
				</div>;
	  }
}

export default withTranslation('Carousel')(Carousel);