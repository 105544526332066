import React, { Component } from "react"
import { withTranslation } from "gatsby-plugin-react-i18next"
import { StaticQuery, graphql, Link } from "gatsby"
import { setPathSlash } from "./lib/utils";

class ExperiencesBreadcrumb extends Component {
  findParentLink(menuLinks, experienceId, articles, language) {
    const parentMenu = menuLinks.find(menuLink => {
      if (menuLink.children) {
        return menuLink.children.some(
          child => child.experience === experienceId
        )
      } else {
        return false
      }
    })
    if (!parentMenu) return [];
    return articles.find(
      article =>
        article.node.articleId === parentMenu.children[0].article &&
        article.node.lang === language
    )
  }

  render() {
    const { t, experienceId } = this.props
    const language = this.props.i18n.language

    return this.generateBreadcrumb(experienceId, language, t)
  }

  generateBreadcrumb(experienceId, language, t) {
    return (
      <StaticQuery
        query={graphql`
          query ExperiencesBreadcrumbQuery {
            site {
              siteMetadata {
                menuLinks {
                  children {
                    experience
                    article
                  }
                }
              }
            }
            allLocalizedArticle(
              sort: { fields: [section, lang, order], order: ASC }
            ) {
              edges {
                node {
                  articleId
                  name
                  path
                  lang
                  section
                }
              }
            }
          }
        `}
        render={data => {
          const article = this.findParentLink(
            data.site.siteMetadata.menuLinks,
            experienceId,
            data.allLocalizedArticle.edges,
            language
          )
          if (!article || !article.node || !article.node.path) return null;
          return (
            <div className="simple-breadcrumb mt-3 mt-xl-0">
              <Link to={`/${language}/`}>{t("Inicio")}</Link>
              {article && (
                <>
                  <span>&gt;</span>
                  <Link to={setPathSlash(article.node.path)}>{article.node.name}</Link>
                </>
              )}
            </div>
          )
        }}
      />
    )
  }
}

export default withTranslation("Experience")(ExperiencesBreadcrumb)
