import React, { Component } from "react"
import parse from "html-react-parser"
import { withTranslation } from "gatsby-plugin-react-i18next"
import GoogleMap from "../components/GoogleMap"
import ContentImage from "../components/ContentImage"

class InformationPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMore: props.isOpen ?? false,
    }
  }

  static defaultProps = {
    showMap: false,
    zoom: 16,
  }

  /**
   * Añade un marcador al mapa
   *
   * @param {google.maps.Map} map 	Mapa de Google Maps
   * @param {google.maps} maps 		Librería de Google Maps
   * @param {double} lat 				Latitud del marcador
   * @param {double} lng 				Longitud del marcador
   */

  renderMarker = (map, maps, lat, lng) => {
    new maps.Marker({
      position: { lat: lat, lng: lng },
      map,
    })
  }

  render() {
    const replaceOptions = {
      trim: true,
      replace: domNode => {
        if (
          domNode.name === "img" &&
          domNode.attribs.src &&
          domNode.attribs.src.startsWith("/")
        ) {
          return <ContentImage image={domNode} />
        }
      },
    }

    return (
      <div className={`mb-3 tamaran destacado-informacion`}>
        <a
          className={`titulo ${this.props.isOpen ? '' : 'collapsed'}`}
          role="button"
          data-toggle="collapse"
          href={`#` + this.props.id + `_body`}
          onClick={() => {
            this.props.onClick()
          }}
          aria-controls="panelCollapse-1"
        >
          <div
            className={`col-12 destacado-informacion-cabecera clickable`}
            role={`button`}
            tabIndex={0}
            data-toggle="collapse"
            data-target={`#` + this.props.id + `_body`}
            onClick={() => this.setState({ showMore: !this.state.showMore })}
            onKeyPress={this.handleKeyPress}
          >
            <h3 className={`panel-title m-0 d-flex justify-content-between align-items-center`}>
              <div>
                {(this.props.showMap || this.props.className) && (
                  <i
                    className={`fa ${this.props.showMap ? "fa-marker" : this.props.className}`}
                    style={{paddingTop: "2px"}}
                    aria-hidden="true"
                  ></i>
                )}
                {(!this.props.showMap && this.props.customIcon &&
                  <img src={this.props.customIcon} className="custom-icon" alt={this.props.id} />
                )}
                {/* <DescriptionIcon /> */}
                <span>{this.props.title}</span>
              </div>
              <div className={`more-less`}>
                {!this.state.showMore ? (
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className={`bi bi-plus`}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule={`evenodd`}
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                    />
                  </svg>
                ) : (
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className={`bi bi-dash`}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule={`evenodd`}
                      d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                )}
              </div>
            </h3>
          </div>
        </a>
        <div
          id={this.props.id + `_body`}
          className={`destacado-informacion-cuerpo  ${this.props.isOpen ? 'show' : 'collapse'}`}
        >
          {this.props.showMap ? (
            <GoogleMap
              classes={`p-2`}
              lat={this.props.coordinates.lat}
              lng={this.props.coordinates.lng}
            ></GoogleMap>
          ) : (
            <div>{parse(this.props.body, replaceOptions)}</div>
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation("InformationPanel")(InformationPanel)
