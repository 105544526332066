import React, {useMemo} from 'react';
import Layout from '../components/Layout';
import Experience from '../components/Experience';
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContentContext from "../context/ContentContext";
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { setPathSlash } from "../components/lib/utils";
import favicon from '../../static/favicon.svg';



export const query = graphql`
	query ExperienceDetails($lang: String, $experienceId: Int, $experienceIdString: String) {
		experience(lang: {eq: $lang}, experienceId: {eq: $experienceId}) {
			siteBaseUrl
			canonical_url
			name
			name_spa
			coordinates {
				lat
				lng
			}
			subcategory {
				name
				name_spa
			}
			tags
			difficulty
			difficulty_id
			experienceId
			duration {
				hours
				minutes
				formatted
			}
			images {
				alt
				remoteImage {
					childImageSharp {
						fixed(width: 655, height:311, fit: COVER, cropFocus: CENTER) {
							src
							srcSet
						}
					}
				}
				title
			}
			originalPath
			lowestRate {
				value
				formatted
				currency
			}
			languages
			information {
				body
				title
				class
			}
			description_summary
			description {
				description
				summaryBlock {
					content
					title
				}
			}
			sections {
				id
				editable
				name
				short_name
			}
			seo {
				title
				description
			}
			products {
				id
				name
				weekDays
				languages
				description {
					description
					summaryBlock {
						content
						title
					}
				}
				lowestRate {
					value
					currency
					formatted
				}
				rates {
					id
					pvp
					customerTypes {
						name
					}
				}
			}
			translations {
				lang
				path
			}
			cancelled
		}
		allFaq(filter: {experiences: {elemMatch: {id: {glob: $experienceIdString}}}, lang: {eq: $lang}}) {
			edges {
				node {
					faqId
				}
			}
		}
	}
`

export default function ExperiencePage( { data } ) {
	const experience = data.experience;
	const allFaq = data.allFaq;
	const { language } = React.useContext(I18nextContext);

	function setOgImage(images, baseUrl) {
		if (images.length > 0) {
			const defaultImage = experience.images[0].remoteImage?.childImageSharp?.fixed?.src;
			if (defaultImage) {
				return <meta name="og:image" content={baseUrl + defaultImage} />;
			}
		}
		return '';
	}
	const memoizedValue = useMemo(() => ({ translations: experience.translations }), [experience.translations]);

	return (
		<ContentContext.Provider value={memoizedValue}>
			<Layout>
				<Helmet>
					<html lang={language}/>
					<title>{experience.seo?.title}</title>
					<link rel="icon" href={favicon}/>
					<meta name="title" content={experience.seo?.title}/>
					<meta name="description" content={experience.seo?.description}/>
					<meta name="og:title" content={experience.seo?.title}/>
					<meta name="og:description" content={experience.seo?.description}/>
					{setOgImage(experience.images, experience.siteBaseUrl)}
					<meta name="og:type" content="product"/>
					<meta name="og:url"
								content={setPathSlash(experience.siteBaseUrl + "/" + language + experience.originalPath)}/>
					<body className={`experience`} data-spy="scroll" data-target="#expSubmenu" data-offset="170"/>
					{experience.translations.map((translation) => <link rel="alternate" key={translation.lang}
					hrefLang={translation.lang}
					href={setPathSlash(experience.siteBaseUrl + translation.path)}/>)}
					<link rel="canonical" href={setPathSlash(experience.canonical_url)}/>
				</Helmet>
				<Experience experience={experience} faq={allFaq.edges.map(faq => String(faq.node.faqId))}/>
			</Layout>
		</ContentContext.Provider>);
}
